<template>
  <div class="home_top">
    <div class="inner inner1">
      <div class="inner1Color">
        <div class="left_item">
          <h4 style="marginTop: 40px;">¿Quieres saber cuál es tu límite de crédito?</h4>
          <div class="item_progress">
            <p>30,000$</p>
            <img src="@/assets/images/loanImg/icon_progress.png" alt="">
          </div>
          <div class="item_info">
            <img class="info_img1" src="@/assets/images/loanImg/icon_info.png" alt="">
            <div class="info_tips">
              <p>5 minutos para procesar su solicitud</p>
              <p>Sin garantía</p>
              <p>Préstamo de 24 horas</p>
            </div>
            <img class="info_img2" src="@/assets/images/loanImg/icon_info2.png" alt="">
          </div>
          <h5>Descargue la APP para comprobar rápidamente su cuota, Click</h5>
          <div class="item_download" @click="downloadApp()">
            <img src="@/assets/images/loanImg/icon_download.png" alt="">
            <p>Descargar APP</p>
          <!-- <a href="">
            <img src="@/assets/images/loanImg/download_now.png" alt="">
          </a> -->
          <!-- <img src="@/assets/images/loanImg/Google-play.png" alt=""> -->
          </div>
        </div>
        <div class="right_item">
          <span>¡Aquí está tu préstamo!</span>
          <!-- <img src="@/assets/images/loanImg/top_app.png" alt=""> -->
        </div>
      </div>
    </div>
    <div class="inner inner2">
      <div class="inner2Color">
        <div class="left_item">
          <p>PPRESTAMOS SEGURO Y PROVECHOSO</p>
          <img src="@/assets/images/loanImg/icon_inner2.png" alt="">
          <div class="item_tips">
            <p>Registrarse en nuestra aplicación </p>
            <p>Brinde la información requerida </p>
            <p>Pasar la verificación  </p>
            <p>Obtenga su prestamo </p>
          </div>
        </div>
        <div class="right_item">
          <img src="@/assets/images/loanImg/icon_people.png" alt="">
        </div>
      </div>
    </div>
    <div class="inner inner3">
      <!-- <h3>Préstamo seguro y conviniente？</h3> -->
      <div class="inner3_item">
        <div class="content">
          <div class="top_item">
            <p>PAGA POR ADELANTADO</p>
            <img src="@/assets/images/loanImg/icon_inner3_1.png" alt="">
          </div>
          <div class="line" />
          <div class="bottom_item">
            Si necesita fondos de contingencia pero no
            tiene tiempo para pagar, podemos ayudarlo
            a resolver sus dificultades y solo necesita
            pagar su salario en el momento acordado.
          </div>
        </div>
        <div class="content">
          <div class="top_item">
            <p>SOLICITUD FÁCIL</p>
            <img src="@/assets/images/loanImg/icon_inner3_2.png" alt="">
          </div>
          <div class="line" />
          <div class="bottom_item">
            Siempre que cumplas los requisitos de
            edad, tengas cuenta bancaria propia,
            teléfono móvil y correo electrónico, puedes
            solicitar nuestro préstamo sin coasegurador
            garantía y solo indicar tu finalidad
          </div>
        </div>
        <div class="content">
          <div class="top_item">
            <p>PRESTAMOS SIN PAPELEOS</p>
            <img src="@/assets/images/loanImg/icon_inner3_3.png" alt="">
          </div>
          <div class="line" />
          <div class="bottom_item">
            Si eres un cliente habitual con crédito,
            realizaremos un seguimiento de su límite
            máximo de préstamo y su solicitud de
            préstamo se procesará rápidamente.
          </div>
        </div>
      </div>
    </div>
    <div class="inner inner4">
      <div class="inner4Color">
        <div class="left_item">
          <img src="@/assets/images/loanImg/icon_people2.png" alt="">
        </div>
        <div class="right_item">
          <p>¿COMO REEMBOLSAR?</p>
          <!-- <img src="@/assets/images/loanImg/icon_inner4.png" alt=""> -->
          <div class="item_tips">
            {{ `Le proporcionaremos un método de pago conveniente para que pueda
            pagar su préstamo rápida y fácilmente para salvar su crédito. Siempre
            puede ponerse en contacto con nuestros asesores profesionales
            después de obtener un préstamo y lo ayudaremos de manera eficiente.
            ` }}
          </div>
        </div>
      </div>
    </div>
    <div class="inner inner5">
      <h3>TESTIMONIO DE NUESTROS CLIENTES</h3>
      <div class="inner5_item">
        <div class="content">
          <div class="left_item">
            <img src="@/assets/images/loanImg/icon_inner5_2.png" alt="">
            <div class="item_info">
              <h4>Ruben Rodriguez</h4>
              <p>Conductor</p>
              <div class="top">
                <img src="@/assets/images/loanImg/icon_inner5.png" alt="">
                <img src="@/assets/images/loanImg/icon_inner5.png" alt="">
                <img src="@/assets/images/loanImg/icon_inner5.png" alt="">
                <img src="@/assets/images/loanImg/icon_inner5.png" alt="">
                <img src="@/assets/images/loanImg/icon_inner5.png" alt="">
              </div>
            </div>
          </div>
          <div class="right_item">
            <div class="bottom">En mi trabajo se estan demorando con mi pago y necesito el dinero urgente para poder pagar el arriendo de mi casa pero gracias a MiRuiqueza pude obtener un prestamomuy facil y rapido. Me ayudo mucho a solucionar mi problema de manera muy buena.
            </div>
          </div>
        </div>
        <div class="content">
          <div class="left_item">
            <img src="@/assets/images/loanImg/icon_inner5_1.png" alt="">
            <div class="item_info">
              <h4>Johan Gomez</h4>
              <p>Estudiante</p>
              <div class="top">
                <img src="@/assets/images/loanImg/icon_inner5.png" alt="">
                <img src="@/assets/images/loanImg/icon_inner5.png" alt="">
                <img src="@/assets/images/loanImg/icon_inner5.png" alt="">
                <img src="@/assets/images/loanImg/icon_inner5.png" alt="">
                <img src="@/assets/images/loanImg/icon_inner5.png" alt="">
              </div>
            </div>
          </div>
          <div class="right_item">
            <div class="bottom">Necesitaba un monto paraterminar de pagar mis estudios pero como soy estudiante me lo negaron en muchos lugares, hasta que conoci MiRuiqueza y me ayudo con un prestamo de manera rapida y sencilla. Ahora puedo obtener mis estudios y todo gracias a su prestamo.
            </div>
          </div>
        </div>
        <div class="content">
          <div class="left_item">
            <img src="@/assets/images/loanImg/icon_inner5_3.png" alt="">
            <div class="item_info">
              <h4>Sandra Salazar</h4>
              <p>Enfermera</p>
              <div class="top">
                <img src="@/assets/images/loanImg/icon_inner5.png" alt="">
                <img src="@/assets/images/loanImg/icon_inner5.png" alt="">
                <img src="@/assets/images/loanImg/icon_inner5.png" alt="">
                <img src="@/assets/images/loanImg/icon_inner5.png" alt="">
                <img src="@/assets/images/loanImg/icon_inner5.png" alt="">
              </div>
            </div>
          </div>
          <div class="right_item">
            <div class="bottom">Siempre me quedaba sin dinero a fin de mes pues no me alcanzaba y tengo una hija la cual tengo que mantener sola y con MiRuiqueza tengo la posibilidad de hacer prestamos y pagar comodamente.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    downloadApp() {
      window.location.href = 'https://play.google.com/store/apps/details?id=prestamo.com.miruiqueza'
    }
  }
}
</script>

<style lang="scss" scoped>
  .home_top{
    min-width: 1200px;
    background: #fff;
    .inner{
      width: 100%;
      padding:10px calc((100% - 1100px) / 2) 0;
      margin: 0 auto 20px;
    }
    .inner1{
      .inner1Color{
        width: 100%;
        height: 560px;
        // background: linear-gradient(to left,#6F43A1, #EC5E3F);
        background: #4C0577;
        // padding: 23px;
        border-radius: 15px;
        display: flex;
      }
      .left_item{
        background: #fff;
        padding: 25px;
        border-radius: 10px;
        position: relative;
        margin: 23px 0 23px 23px;
        flex: 1;
        h3{
          font-size: 24px;
          color: #333333;
          font-weight: 700;
          margin-bottom: 30px;
        }
        h4{
          font-size: 16px;
          color: #333333;
          font-weight: 700;
          margin-bottom: 20px;
        }
        .item_progress{
          width: 230px;
          p{
            width: 90px;
            text-align: center;
            line-height: 30px;
            border-radius: 5px;
            color: white;
            font-size: 12px;
            font-weight: bold;
            margin: 0 auto 10px;
            background: #333333;
          }
          img{
            width: 245px;
            height: 15px;
          }
        }
        .item_info{
          margin: 20px 0;
          display: flex;
          height: 170px;
          .info_img1{
            width: 30px;
          }
          .info_tips{
            display: flex;
            flex-direction: column;
            p{
              flex: 1;
              display: flex;
              box-sizing: border-box;
              margin-left: 10px;
              font-size: 13px;
              color: #666666;
              font-weight: bold;
            }
            p:nth-child(1){
              padding-top: 8px;
            }
            :nth-child(2){
              align-items: center;
            }
            :nth-child(3){
              align-items:flex-end;
              padding-bottom: 8px;
            }
          }
          .info_img2{
            margin: 60px 15px 15px 40px;
            width: 135px;
            height: 110px;
          }
        }
        .item_download{
          display: flex;
          margin: 20px 0 0;
          width: 250px;
          background: black;
          color: white;
          border-radius: 5px;
          align-items: center;
          line-height: 40px;
          justify-content: center;
          img{
            width: 15px;
            height: 15px;
            margin-right: 5px;
          }
        }
      }
      .right_item{
        flex: 1;
        display: flex;
        flex-direction: column;
        background: url("~@/assets/images/loanImg/top_app.png") center no-repeat;
        background-size: contain;
        span {
          color: #FFFFFF;
          margin-top: 40px;
          font-size: 20px;
          text-align: center;
          margin-bottom: 30px;
        }
        img{
          width: 310px;
          height: 360px;
        }
      }
    }
    .inner2{
      .inner2Color{
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 350px;
        background: #F4EFFE;
        padding: 23px;
        border-radius: 15px;
      }
      .left_item{
        flex: 1;
        margin-right:30px;
        p{
          max-width: 450px;
          margin-top: 20px;
          color:#333333 ;
          font-size: 38px;
          font-weight: bold;
        }
        img{
          width: 575px;
          height: 100px;
          margin: 40px 0 0px;
        }
        .item_tips{
          display: flex;
          width: 580px;
          justify-content: space-between;
          p{
            width:135px;
            font-size: 12px;
            color: #333333;
            text-align: center;
          }
        }
      }
      .right_item{
        display: flex;
        align-items: center;
        img{
          width: 300px;
          height: 280px;
          margin-right: 20px;
        }
      }
    }
    .inner3{
      h3{
        font-size: 24px;
      }
      .inner3_item{
        margin: 50px 0;
        display: flex;
        justify-content: space-between;
        .content{
          position: relative;
          background: white;
          width: 280px;
          padding: 35px 20px 20px 20px;
          border-radius: 15px;
          box-shadow: 0px 3px 20px 1px rgba(93,93,93,0.1600);
          & ::before{
            content: '';
            width: 280px;
            height: 15px;
            background: #9E7CBC;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 15px 15px 0  0;
          }
          .top_item{
            display: flex;
            justify-content: space-between;
            p{
              width: 110px;
              font-weight: 600;
              font-size: 14px;
            }
            img{
              width: 30px;
              height: 30px;
            }
          }
          .line{
            width: 120px;
            height: 2px;
            background: #9E7CBC;
            margin: 15px 0;
          }
          .bottom_item{
            line-height: 18px;
            color: #666666;
          }
        }
      }
    }
    .inner4{
      .inner4Color{
        position: relative;
        height: 340px;
        background: #F4EFFE;
        display: flex;
        justify-content: space-between;
        padding: 23px;
        border-radius: 15px;
      }
      .left_item{
        img{
          margin-left: 20px;
          width: 280px;
          height: 280px;
        }
      }
      .right_item{
        margin-right:50px;
        p{
          margin: 40px 0;
          color:#333333 ;
          font-size: 20px;
          font-weight: bold;
        }
        img{
          position: absolute;
          width: 190px;
          height:220px;
          right: calc(15%);
          top: 0px;
        }
        .item_tips{
          background: #FFFFFF;
          border: 1px solid #9E7CBC;
          border-radius: 10px 0px 10px 10px;
          padding: 20px 10px;
          width: 450px;
          line-height: 20px;
        }
      }
    }
    .inner5{
      h3{
        font-size: 24px;
      }
      .inner5_item{
        margin: 45px 0;
        display: flex;
        justify-content: space-between;
        .content{
          width: 300px;
          box-shadow: 0px 3px 20px 1px rgba(93,93,93,0.16);
          border-radius: 20px 20px 20px 20px;
          display: flex;
          flex-direction: column;
          padding: 15px;
          .left_item{
            display: flex;
            img{
              width: 70px;
              height: 70px;
              margin-right: 20px;
            }
            margin: 0 10px 10px 0;
            .top{
              img{
                width: 10px;
                height: 10px;
                margin-right: 2px;
                margin-left: 2px;
              }
            }
            .item_info{
              display: flex;
              flex-direction: column;
              justify-content: center;
              line-height: 22px;
              p{
                font-size: 12px;
                color: #666666;
              }
            }
          }
          .line{
            width: 120px;
            height: 2px;
            background: #4485EC;
            margin: 15px 0;
          }
          .right_item{
            .bottom{
              margin-top: 5px;
              font-size: 12px;
              line-height: 15px;
              color: #666666;
            }
          }
        }
      }
    }
  }
@media only screen and (max-width: 720px){
  .home_top{
    min-width: calc(100vw) !important;
    .inner{
      min-width: calc(100vw) !important;
      display: flex;
      flex-wrap: wrap;
    }
    .inner1{
      position: relative;
      h4{
        margin-top: 0px !important;
      }
      .inner1Color{
        width: 100%;
        height: 800px;
        background: linear-gradient(to left,#6F43A1, #EC5E3F);
        padding: 23px;
        border-radius: 15px;
        flex-direction: column;
      }
      .left_item{
        margin: 0px 20px 20px;
        height: 530px;
        .item_info{
          .info_img2{
            margin: 50px 10px 15px 10px;
            width: 70px;
            height: 70px;
          }
        }
      }
      .right_item{
        // width: calc(100vw);
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        span {
          color: #FFFFFF;
          margin-top: 20px;
          font-size: 30px;
          text-align: center;
          margin-bottom: 20px;
        }
        img{
          width: 260px !important;
          height: 310px;
          // position: absolute;
          // bottom: 0;
          // left: 40px;
        }
      }
    }
    .inner2{
      .inner2Color{
        flex-direction: column;
        height: 400px;
      }
      .left_item{
        margin-right: 0;
        width: calc(100vw - 40px);
        p{
          font-size: 18px;
        }
        img{
          width: 100%;
          height: 60px;
        }
        .item_tips{
          width: calc(100vw - 40px);
          p{
            font-size: 12px !important;
            width: 70px;
          }
        }
      }
      .right_item{
        // width: calc(100vw);
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 120px;
          height: 120px;
          margin-right: 0;
        }
      }
    }
    .inner3{
      width: calc(100vw - 40px) !important;
      margin: 0 auto !important;
      h3{
        font-size: 18px;
        padding: 0 20px;
      }
      .inner3_item{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .content{
          margin: 20px 0;
        }
      }
    }
    .inner4{
      .inner4Color{
        width: 100%;
        flex-direction: column;
        height: 600px;
      }
      .left_item{
        // width: calc(100vw);
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 200px;
          height: 220px;
        }
      }
      .right_item{
        width: calc(100vw - 40px) !important;
        margin: 0 auto !important;
        position: relative;
        .item_tips{
          width: 100%;
        }
        img{
          position: absolute;
          width: 100px;
          height: 140px;
          right: 0;
          top: -20px;
        }
      }
    }
    .inner5{
      min-width: calc(100vw - 40px) !important;
      width: calc(100vw - 40px) !important;
      margin: 0 auto !important;
      h3{
        font-size: 18px;
      }
      .inner5_item{
        display: flex;
        flex-wrap: wrap;
        .content{
          margin: 20px 0;
        }
      }
    }
  }
}
</style>
